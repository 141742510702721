// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$WebApp-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$WebApp-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$WebApp-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$WebApp-theme: mat.m2-define-light-theme((color: (primary: $WebApp-primary,
  accent: $WebApp-accent,
  warn: $WebApp-warn,
)));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($WebApp-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

.form-label {
  color: #90D2B5;
  font-weight: bold;
}

.ui-widget,
.ui-widget .ui-widget {
  font-size: 12px !important;
}

.p-listbox .p-listbox-list {
  padding: 0 0;
}

.p-datatable .p-datatable-thead > tr > th {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}




.p-datatable .p-datatable-tbody > tr > td {
  padding: 8px;
  text-align: center;
  font-size: 14px;
}

.p-sortable-column-icon, .pi-filter-icon {
  font-size: 12px !important;

}

.p-column-filter-menu-button {
  width: 12px !important;
}

.p-sortable-column-icon, .pi-filter-icon {
  color: white !important;
}

.p-column-filter-menu-button-active {
  background: none !important;
}

.mat-mdc-card-title {
  font-size: 20px !important;
  margin-bottom: 10px !important;
  color: #656565;
}

.ng-valid[required], .ng-valid.required  {
  border-left: 5px solid #42A948; /* green */
}

.ng-invalid:not(form)  {
  border-left: 5px solid #a94442; /* red */
}

.p-button.p-button-icon-only {
  padding: 5px;
  width: 30px;
  height: 30px !important;
  font-size: 12px;
}


/* style for scrollbar */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border-radius: 4px;

  &:hover {
    background: #e1e1e1;
  }
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #e1e1e1 #f1f1f1;
}

.p-dynamic-dialog {
  label {
    margin-top: .5rem;
  }
}

.btn-custom-outlined {
  padding: 2px 0px;
  width: 80.11px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: solid #fff;
  background-color: transparent;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  border-radius: 3px;
}
