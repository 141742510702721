$slate-gray: #1a2124;
$mist-gray: #d7d5d5;
$bright-lime: #99dd05;
$electric-pear: #cbf93d;
$vibrant-leaf: #4adb07;
$clover-green: #038c33;


$font-family: Arial, "Arial", sans-serif !important;

$tittle-size: 20px;
