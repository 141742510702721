@use 'consts';

body {
  margin: 0;
  font-family: Arial, "Arial", sans-serif !important;
  background-color: consts.$mist-gray !important;

  .p-radiobutton {
    .p-highlight {
      &:hover {
        border-color: consts.$electric-pear !important;
        background: consts.$electric-pear !important;
      }

      border-color: consts.$electric-pear !important;
      background: consts.$electric-pear !important;
    }
  }

  p-togglebutton {
    button {
      padding: 0 10px !important;
      height: 29px;
      width: 80px;
      background-color: consts.$bright-lime !important;
      color: consts.$slate-gray !important;
      border: none !important;
      margin-right: 15px !important;

      &.p-highlight {
        background-color: consts.$electric-pear !important;
      }
    }

  }

  input {
    &[type=text] {
      &:focus {
        border-color: consts.$electric-pear !important;
        box-shadow: none !important;
      }

    }
  }

  textarea {

    &:focus {
      border-color: consts.$electric-pear !important;
      box-shadow: none !important;
    }


  }

  .p-element:focus {
    box-shadow: none !important;
  }

  .mat-mdc-card-title {
    color: consts.$slate-gray !important;
    font-size: consts.$tittle-size !important;
  }

  .p-component {
    font-family: consts.$font-family
  }

  .main-banner {
    background-color: white !important;

  }

  .primary-background {
    background-color: white !important;
  }

  .secondary-background {
    background-color: consts.$bright-lime !important;
  }

  .main-logo {
    width: 20%;
    height: 20%;
    align-self: center;
  }

  .p-menubar-item {
    &.p-focus {
      .p-menubar-item-content {
        -webkit-appearance: none !important;
        -webkit-border-radius: 0px !important;
        background: consts.$mist-gray !important;
      }
    }
  }

  .p-menubar {
    padding: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    background: #f8f9fa !important;
    color: #495057 !important;

    .p-menuitem-link-active,
    .p-menubar-item-icon,
    .p-menubar-item-label {
      color: consts.$slate-gray !important;
    }

    .p-menubar-item-link:hover {
      @extend .primary-background;
      -webkit-appearance: none !important;
      -webkit-border-radius: 0px !important;
      background-color: consts.$mist-gray !important;

    }
  }

  .p-datatable-hoverable {
    .p-selectable-row {
      cursor: pointer !important;
    }

    .p-selectable-row:hover {
      background-color: consts.$mist-gray !important;
    }
  }

  .p-datatable {

    .p-datatable-tbody {
      tr {
        background: #ffffff !important;
        color: #495057 !important;
        transition: box-shadow 0.2s !important;
      }

      td {

        border: 1px solid #dee2e6;
        border-width: 0 0 1px 0;

      }
    }

    .p-column-filter-menu-button {
      &:focus {
        background-color: inherit !important;
        border: none !important;
        box-shadow: none !important;
      }

      &:hover {
        background-color: consts.$clover-green !important;
        border: none;
      }

    }

    .p-column-filter-menu-button-open {
      @extend .primary-background;
      border: none;
    }

    .p-datatable-header {
      background-color: consts.$slate-gray !important;
      padding: 5px;
      border-width: 1px;
      color: white;
    }

    .p-sortable-column {
      &.p-highlight {
        background-color: consts.$clover-green !important;
        color: white !important;

        &.pi-filter-icon {
          color: white !important;
        }
      }
    }

    .p-datatable-thead {
      tr {
        th {
          background-color: consts.$slate-gray !important;
          padding: 5px;
          border-width: 1px;
          color: white;
        }
      }

      th:hover {
        background-color: consts.$clover-green !important;
        color: white !important;
      }
    }

    .p-paginator {
      background: #ffffff;
      color: #6c757d;
      border: 0 solid #e9ecef;
      padding: 0.5rem 1rem;
      border-radius: 6px;

      .p-select {
        background: #ffffff;
        border: 1px solid #ced4da;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        border-radius: 6px;

        .p-select-label {
          color: #6c757d;
        }
      }

      .p-select-overlay {
        background: #ffffff;
        color: #6c757d;
      }

      .p-select-option {
        color: #6c757d;
      }

      .p-paginator-page-selected {
        background: #6c757d;
      }
    }
  }

  .action-button {
    button {
      background-color: consts.$clover-green !important;
      border: none !important;
      color: white !important;

      &:hover {
        background-color: consts.$vibrant-leaf !important;
      }
    }

    + .action-button {
      margin-left: 2px;
    }
  }

  .p-fileupload-choose {
    background-color: consts.$slate-gray !important;
    color: white !important;
    border: none !important;
    height: 35px;

    &:hover {
      background-color: consts.$clover-green !important;
    }
  }

  button {
    &.p-button {
      &.primary {
        height: 35px;
        background-color: consts.$slate-gray !important;
        color: white !important;
        border: none !important;

        &:hover {
          background-color: consts.$clover-green !important;
        }
      }

      &.secondary {
        height: 35px;
        background-color: consts.$clover-green !important;
        color: white !important;
        border: none !important;

        &:hover {
          background-color: consts.$mist-gray !important;
        }
      }
    }
  }

  .p-inputnumber {
    width: 100% !important;

    .p-inputnumber-input {
      padding: 0 !important;
      border: none !important;
    }
  }

  .p-inputtext, .search-header input {
    font-size: 1rem;
    color: #495057;
    background: #ffffff;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 6px;
  }

  .p-dialog {
    .p-dialog-header {
      border-bottom: 0 none;
      background: #ffffff;
      color: #343a40;
      padding: 1.5rem;
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
    }

    .p-dialog-content {
      background: #ffffff;
      color: #495057;
      padding: 0 1.5rem 2rem 1.5rem;
    }
  }
  .p-multiselect,
  .p-select{
    background: #ffffff !important;
    border: 1px solid #ced4da;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
  }
}
