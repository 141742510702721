body {
  margin: 0;
  font-family: Arial, "Arial", sans-serif !important;
  background-color: #bbbcbd !important;
}
body .p-radiobutton .p-highlight {
  border-color: #cbf93d !important;
  background: #cbf93d !important;
}
body .p-radiobutton .p-highlight:hover {
  border-color: #cbf93d !important;
  background: #cbf93d !important;
}
body p-togglebutton .p-button {
  padding: 0 10px !important;
  height: 29px;
  width: 80px;
  background-color: #99dd05 !important;
  color: #1a2124 !important;
  border: none !important;
  margin-right: 15px !important;
}
body p-togglebutton .p-button.p-highlight {
  background-color: #cbf93d !important;
}
body input[type=text]:focus {
  border-color: #cbf93d !important;
  box-shadow: none !important;
}
body textarea:focus {
  border-color: #cbf93d !important;
  box-shadow: none !important;
}
body .p-element:focus {
  box-shadow: none !important;
}
body .mat-mdc-card-title {
  color: #1a2124 !important;
  font-size: 20px !important;
}
body .p-component {
  font-family: Arial, "Arial", sans-serif !important;
}
body .main-banner {
  background-color: white !important;
}
body .primary-background, body .p-datatable .p-column-filter-menu-button-open, body .p-menubar .p-menuitem-link:hover {
  background-color: white !important;
}
body .secondary-background {
  background-color: #99dd05 !important;
}
body .main-logo {
  width: 20%;
  height: 20%;
  align-self: center;
}
body .p-menubar .p-menuitem-icon,
body .p-menubar .p-menuitem-text {
  color: #1a2124 !important;
}
body .p-menubar .p-menuitem-link:hover {
  -webkit-appearance: none !important;
  -webkit-border-radius: 0px !important;
  background-color: #bbbcbd !important;
}
body .p-datatable .p-column-filter-menu-button:focus {
  background-color: inherit !important;
  border: none !important;
  box-shadow: none !important;
}
body .p-datatable .p-column-filter-menu-button:hover {
  background-color: #038c33 !important;
  border: none;
}
body .p-datatable .p-column-filter-menu-button-open {
  border: none;
}
body .p-datatable .p-datatable-header {
  background-color: #1a2124 !important;
  padding: 5px;
  border-width: 1px;
  color: white;
}
body .p-datatable .p-sortable-column.p-highlight {
  background-color: #038c33 !important;
  color: white !important;
}
body .p-datatable .p-sortable-column.p-highlight.pi-filter-icon {
  color: white !important;
}
body .p-datatable .p-datatable-thead tr th {
  background-color: #1a2124 !important;
  padding: 5px;
  border-width: 1px;
  color: white;
}
body .p-datatable .p-datatable-thead th:hover {
  background-color: #038c33 !important;
  color: white !important;
}
body .action-button button {
  background-color: #038c33 !important;
  border: none !important;
  color: white !important;
}
body .action-button button:hover {
  background-color: #4adb07 !important;
}
body .action-button + .action-button {
  margin-left: 2px;
}
body .p-fileupload-choose {
  background-color: #1a2124 !important;
  color: white !important;
  border: none !important;
  height: 35px;
}
body .p-fileupload-choose:hover {
  background-color: #038c33 !important;
}
body button.p-button.primary {
  height: 35px;
  background-color: #1a2124 !important;
  color: white !important;
  border: none !important;
}
body button.p-button.primary:hover {
  background-color: #038c33 !important;
}
body button.p-button.secondary {
  height: 35px;
  background-color: #038c33 !important;
  color: white !important;
  border: none !important;
}
body button.p-button.secondary:hover {
  background-color: #bbbcbd !important;
}
body .p-inputnumber {
  width: 100% !important;
}
body .p-inputnumber .p-inputnumber-input {
  padding: 0 !important;
  border: none !important;
}